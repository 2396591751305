import React, { useState, useEffect } from "react";
import Card from "./Card";

const Deck = () => {
  const suits = ["Acorns", "Bells", "Hearts", "Leaves"];
  const ranks = ["7", "8", "9", "10", "J", "Q", "K", "A"];

  // Generate the full deck
  const initialDeck = suits.flatMap((suit) =>
    ranks.map((rank) => ({ rank, suit }))
  );

  // Game rules
  const rules = {
    7: "Pije osoba lijevo od tebe",
    8: "Pije osoba desno od tebe",
    9: "Piju svi",
    10: "Nikad nisam ...",
    J: "Pogađaš iduću boju",
    Q: "Dama pije sama",
    K: "Biraš tko pije",
    A: "Kategorije ...",
  };

  const [deck, setDeck] = useState([]);
  const [currentCard, setCurrentCard] = useState(null);

  useEffect(() => {
    shuffleDeck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shuffleDeck = () => {
    const shuffledDeck = [...initialDeck];
    for (let i = shuffledDeck.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledDeck[i], shuffledDeck[j]] = [shuffledDeck[j], shuffledDeck[i]];
    }
    setCurrentCard(null);
    setDeck(shuffledDeck);
  };

  const drawCard = () => {
    if (deck.length > 0) {
      const [nextCard, ...remainingDeck] = deck;
      setCurrentCard(nextCard);
      setDeck(remainingDeck);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <h1 className="mb-4 text-2xl font-bold">Dama Pije Sama</h1>

      <p className="mb-2 text-lg">Preostalo karata: {deck.length}</p>

      <Card card={currentCard} onClick={drawCard} />

      <p className="mt-4 text-2xl">
        {currentCard ? rules[currentCard.rank] : "Dobrodošli!"}
      </p>

      <button
        onClick={shuffleDeck}
        className="mt-4 p-2 bg-blue_favicon hover:bg-blue-500 text-white rounded"
      >
        Resetiraj
      </button>
    </div>
  );
};

export default Deck;
