import React, { useEffect, useState } from "react";
import Deck from "./components/Deck";

function App() {
  const [vh, setVh] = useState(window.innerHeight * 0.01);

  useEffect(() => {
    // Update the vh unit on resize to account for the mobile browser address bar
    const handleResize = () => {
      setVh(window.innerHeight * 0.01);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="flex items-center justify-center bg-gray-100 p-4"
      style={{
        height: `calc(${vh}px * 100)`, // Dynamically set height based on vh unit
        minHeight: "-webkit-fill-available",
        overflow: "hidden", // Prevent scrolling
      }}
    >
      <Deck />
    </div>
  );
}

export default App;
