import React from "react";

const Card = ({ card, onClick }) => {
  const cardSize = {
    width: "min(90vw, 60vh)",
    height: "min(120vw, 80vh)",
  };

  const fontSizeCorner = "2em";
  const fontSizeMiddle = "3.5em";

  const containerStyle = {
    ...cardSize,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  };

  if (!card) {
    return (
      <div
        className="relative bg-white border-2 border-black rounded-3xl p-4 cursor-pointer shadow-lg"
        style={containerStyle}
        onClick={onClick}
      >
        <div
          className="flex items-center justify-center text-center h-full font-bold text-gray-800"
          style={{ fontSize: fontSizeMiddle }}
        >
          Pritisni za početak
        </div>
      </div>
    );
  }

  const suitSymbols = {
    Acorns: "🌰",
    Bells: "🔔",
    Hearts: "❤️",
    Leaves: "🍀",
  };

  const getRankSymbols = () => {
    const symbol = suitSymbols[card.suit];
    switch (card.rank) {
      case "7":
        return Array(7).fill(symbol).join(" ");
      case "8":
        return Array(8).fill(symbol).join(" ");
      case "9":
        return Array(9).fill(symbol).join(" ");
      case "10":
        return Array(10).fill(symbol).join(" ");
      case "J":
        return `🤴 ${symbol}`;
      case "Q":
        return `👸 ${symbol}`;
      case "K":
        return `👑 ${symbol}`;
      case "A":
        return `🃏 ${symbol}`;
      default:
        return symbol;
    }
  };

  return (
    <div
      className="relative bg-white border-2 border-black rounded-3xl p-4 cursor-pointer shadow-lg"
      style={containerStyle}
      onClick={onClick}
    >
      {/* Top left corner */}
      <div
        className="absolute top-4 left-4 flex flex-col items-center select-none"
        style={{ fontSize: fontSizeCorner, fontWeight: "bold", color: "#333" }}
      >
        <div className="flex items-center justify-center">
          <span>{card.rank}</span>
        </div>
        <div className="flex items-center justify-center mt-1">
          <span>{suitSymbols[card.suit]}</span>
        </div>
      </div>

      {/* Bottom right corner */}
      <div
        className="absolute bottom-4 right-4 flex flex-col items-center select-none"
        style={{ fontSize: fontSizeCorner, fontWeight: "bold", color: "#333" }}
      >
        <div className="flex items-center justify-center">
          <span>{card.rank}</span>
        </div>
        <div className="flex items-center justify-center mt-1">
          <span>{suitSymbols[card.suit]}</span>
        </div>
      </div>

      {/* Center artwork */}
      <div
        className="flex items-center justify-center h-full text-center select-none"
        style={{ fontSize: fontSizeMiddle }}
      >
        {getRankSymbols(card.rank)}
      </div>
    </div>
  );
};

export default Card;
